<template>
  <b-container fluid>
      <iq-card>
        <template v-slot:headerTitle>
          <h4 class="card-title">{{$t('elearning_config.duplicate_certificate_request')}} {{ $t('globalTrans.search') }}</h4>
        </template>
        <template v-slot:body>
              <b-row>
                <b-col lg="6" sm="12">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="training_type_id"
                  >
                    <template v-slot:label>
                      {{$t('elearning_iabm.profession_type')}}
                    </template>
                    <b-form-select
                      plain
                      v-model="search.profession_type"
                      :options="professionTypeList"
                      id="training_type_id"
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col lg="6" sm="12" v-if="search.profession_type === 1">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="org_id"
                  >
                    <template v-slot:label>
                      {{$t('externalUserIrrigation.organization')}}
                    </template>
                    <b-form-select
                      plain
                      v-model="search.org_id"
                      :options="orgList"
                      id="org_id"
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col lg="6" sm="12">
                      <b-form-group
                      class="row"
                      label-cols-sm="4"
                      :label="$t('globalTrans.start_date')"
                      label-for="start_date"
                      >
                      <b-form-input
                          v-model="search.start_date"
                          placeholder="Select Date"
                          id="datepicker"
                      ></b-form-input>
                      </b-form-group>
                </b-col>
                <b-col lg="6" sm="12">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('globalTrans.end_date')"
                    label-for="end_date"
                    >
                    <b-form-input
                        v-model="search.end_date"
                        placeholder="Select Date"
                        id="datepicker"
                    ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col lg="6" sm="12">
                    <b-form-group
                    class="row"
                    label-cols-sm="4"
                    :label="$t('globalUserData.mobile_no')"
                    label-for="mobile"
                    >
                    <b-form-input
                      oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"
                      id="mobile"
                      v-model="search.mobile"
                    ></b-form-input>
                    </b-form-group>
                </b-col>
                <b-col lg="6" sm="12">
                  <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="status"
                  >
                    <template v-slot:label>
                      {{$t('globalTrans.status')}}
                    </template>
                    <b-form-select
                      plain
                      v-model="search.status"
                      :options="statusList"
                      id="status"
                      >
                      <template v-slot:first>
                          <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                      </template>
                      </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col md="2" sm="12">
                    <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                </b-col>
              </b-row>
          </template>
      </iq-card>
      <b-row>
          <b-col md="12">
              <iq-card>
                  <template v-slot:headerTitle>
                      <h4 class="card-title">{{$t('elearning_config.duplicate_certificate_request')}} {{$t('globalTrans.list')}}</h4>
                  </template>
                  <template v-slot:headerAction1>

                  </template>
                  <template v-slot:headerAction>
                      <!-- <b-button class="btn_add_new mr-2" @click="pdfExport">
                        <i class="fas fa-print"></i>{{ $t('globalTrans.print') }}
                      </b-button> -->
                      <!-- <router-link class="btn btn_add_new" :to="{ path: '/training-e-learning-service/iabm/duplicate-certificate-form' }">
                        <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
                      </router-link> -->
                  </template>
                  <template v-slot:body>
                      <b-overlay :show="loadingState">
                          <b-row>
                              <b-col md="12">
                                <div class="table-responsive">
                                  <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                      <template v-slot:cell(index)="data">
                                      {{ $n(data.index + pagination.slOffset) }}
                                      </template>
                                      <template v-slot:cell(applicant_name)="data">
                                        <span class="capitalize">{{ data.item.name_en }}</span>
                                      </template>
                                      <template v-slot:cell(mobile)="data">
                                        {{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(data.item.mobile, { useGrouping: false }) }}
                                      </template>
                                      <template v-slot:cell(organization)="data">
                                        <span v-if="data.item.profession_type != 1">
                                          {{ ($i18n.locale=='bn') ? data.item.other_org_name_bn : data.item.other_org_name }}
                                        </span>
                                        <span v-if="data.item.profession_type == 1">
                                          {{ getOrgName(data.item.org_id) }}
                                        </span>
                                      </template>
                                      <template v-slot:cell(designation)="data">
                                        <span v-if="data.item.designation_id">{{ getDesignation(data.item.designation_id) }}</span>
                                        <span v-else>{{ ($i18n.locale=='bn') ? data.item.designation_bn : data.item.designation_en}}</span>
                                      </template>
                                      <template v-slot:cell(office)="data">
                                        <span v-if="data.item.profession_type == 1">{{ getOfficeName(data.item.office_id) + ', ' + getOrgName(data.item.org_id) }}</span>
                                        <span v-if="data.item.profession_type != 1">{{ ($i18n.locale=='bn') ? data.item.other_org_name_bn : data.item.other_org_name }}</span>
                                        <!-- <span v-if="data.item.org_id">{{ getOfficeName(data.item.office_id) + ' , ' + getOrgName(data.item.org_id) }}</span> -->
                                      </template>
                                      <template v-slot:cell(pay_status)="data">
                                        <span class="badge badge-success" v-if="data.item.pay_status == 'success'">{{$t('globalTrans.success')}}</span>
                                        <span class="badge badge-warning" v-if="data.item.pay_status == 'cancel'">{{$t('globalTrans.cancelled')}}</span>
                                        <span class="badge badge-danger" v-if="data.item.pay_status == 'decline'">{{$t('globalTrans.declined')}}</span>
                                        <!-- <span v-if="data.item.org_id">{{ getOfficeName(data.item.office_id) + ' , ' + getOrgName(data.item.org_id) }}</span> -->
                                      </template>
                                      <template v-slot:cell(status)="data">
                                          <span class="badge badge-warning" v-if="data.item.is_delivered === 0">{{$t('elearning_config.pending')}}</span>
                                          <!-- <span class="badge badge-danger" v-else-if="data.item.status == 2">{{$t('globalTrans.rejected')}}</span> -->
                                          <span class="badge badge-success" v-else>{{$t('globalTrans.delivered')}}</span>
                                      </template>
                                      <template v-slot:cell(action)="data">
                                          <a href="javascript:" class="btn_table_action table_action_view" title="View Details" v-b-modal.modal-5 @click="details(data.item)"><i class="fas fa-eye"></i></a>
                                          <!-- <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4> <router-link :to="{ path: '/training-e-learning-service/iabm/registration-form', query: { id: data.item.id }}" size="sm"><i class="ri-ball-pen-fill"></i> </router-link></a> -->
                                          <span v-if="data.item.is_delivered < 1">
                                              <a href="javascript:" class="btn_table_action table_action_status" title="Delivered" @click="approve(data.item)"><i class="fa fa-check"></i></a>
                                          </span>
                                          <span v-if="data.item.status === 1">
                                              <a href="javascript:" class="btn_table_action table_action_toggle" title="Reject" @click="reject(data.item)"><i class="fas fa-times"></i></a>
                                          </span>
                                      </template>
                                  </b-table>
                                  </div>
                                  <b-pagination
                                      align="center"
                                      v-model="pagination.currentPage"
                                      :per-page="pagination.perPage"
                                      :total-rows="pagination.totalRows"
                                      @input="searchData"
                                      />
                              </b-col>
                          </b-row>
                      </b-overlay>
                  </template>
              </iq-card>
          </b-col>
      </b-row>
      <b-modal id="modal-5" size="lg" :title="$t('elearning_config.duplicate_certificate_request') + ' ' + this.$t('globalTrans.details')" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
          <div class="d-flex">
            <b-button @click="pdfExportDetails('download')" class="ml-4 btn-success water-test-pdf-button2 text-right">
              {{  $t('globalTrans.download') }}
          </b-button>
          <b-button @click="pdfExportDetails('pdf')" class="ml-4 btn-success water-test-pdf-button text-left">
              {{  $t('globalTrans.export_pdf') }}
          </b-button>
          </div>
          <Details :item="item" :key="id" ref="details"/>
      </b-modal>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { duplicateCertificateRequestList, duplicateCertificateRequestApprove, duplicateCertificateRequestReject } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import Details from './Details'
import Store from '@/store'
import flatpickr from 'flatpickr'

export default {
  mixins: [ModalBaseMasterList],
  components: {
      Details
  },
  data () {
      return {
          search: {
            profession_type: 0,
            org_id: 0,
            status: 0,
            mobile: '',
            start_date: '',
            end_date: ''
          },
          trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
          id: 0,
          item: '',
          type: ''
      }
  },
  computed: {
      userTypeList () {
          const userList = [
          { value: 1, text: this.$i18n.locale === 'en' ? 'Trainer' : 'প্রশিক্ষক', text_en: 'Trainer', text_bn: 'প্রশিক্ষক' },
          { value: 2, text: this.$i18n.locale === 'en' ? 'Trainee' : 'প্রশিক্ষণার্থী', text_en: 'Trainee', text_bn: 'প্রশিক্ষণার্থী' }
          ]
          return userList
      },
      professionTypeList: function () {
        return [
          {
            value: 1,
            text: this.$i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়/আওতাধীন সংস্থা' : 'MoA/Organization under MoA',
            text_en: 'MoA/Organization under MoA',
            text_bn: 'কৃষি মন্ত্রণালয়/আওতাধীন সংস্থা'
          },
          {
            value: 2,
            text: this.$i18n.locale === 'bn' ? 'অন্যান্য সরকারী সংস্থা' : 'Other Government Organization',
            text_en: 'Other Government Organization',
            text_bn: 'অন্যান্য সরকারী সংস্থা'
          },
          {
            value: 3,
            text: this.$i18n.locale === 'bn' ? 'বেসরকারি সংস্থা' : 'Non-Goverment Organization',
            text_en: 'Non-Goverment Organization',
            text_bn: 'বেসরকারি সংস্থা'
          }
        ]
      },
      statusList: function () {
        return [
          {
            value: 1,
            text: this.$i18n.locale === 'bn' ? 'বিচারাধীন' : 'Pending',
            text_en: 'Pending',
            text_bn: 'বিচারাধীন'
          },
          {
            value: 2,
            text: this.$i18n.locale === 'bn' ? 'প্রত্যাখ্যাত' : 'Rejected',
            text_en: 'Rejected',
            text_bn: 'প্রত্যাখ্যাত'
          },
          {
            value: 3,
            text: this.$i18n.locale === 'bn' ? 'অনুমোদিত' : 'Approved',
            text_en: 'Approved',
            text_bn: 'অনুমোদিত'
          }
        ]
      },
      fiscalYearList: function () {
          return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      },
      officeTypeList: function () {
          return this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
      },
      officeList: function () {
          return this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      },
      orgList: function () {
          return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      },
      trainerEvaluationList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainerEvaluationList.filter(item => item.status === 1)
      },
      formTitle () {
          return (this.editItemId === 0) ? this.$t('elearning_config.registration') + ' ' + this.$t('globalTrans.entry') : this.$t('elearning_config.registration') + ' ' + this.$t('globalTrans.update')
      },
      columns () {
          const labels = [
              { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
              { label: this.$t('globalTrans.name'), class: 'text-center' },
              { label: this.$t('globalTrans.organization'), class: 'text-center' },
              { label: this.$t('globalTrans.office'), class: 'text-center' },
              { label: this.$t('globalTrans.designation'), class: 'text-center' },
              { label: this.$t('globalTrans.mobile'), class: 'text-center' },
              { label: this.$t('globalTrans.payment') + ' ' + this.$t('globalTrans.status'), class: 'text-center' },
              { label: this.$t('globalTrans.status'), class: 'text-center' },
              { label: this.$t('globalTrans.action'), class: 'text-center' }
          ]

          let keys = []

          if (this.$i18n.locale === 'bn') {
              keys = [
              { key: 'index' },
              { key: 'applicant_name' },
              { key: 'organization' },
              { key: 'office' },
              { key: 'designation' },
              { key: 'mobile' },
              { key: 'pay_status' },
              { key: 'status' },
              { key: 'action' }
              ]
          } else {
              keys = [
              { key: 'index' },
              { key: 'applicant_name' },
              { key: 'organization' },
              { key: 'office' },
              { key: 'designation' },
              { key: 'mobile' },
              { key: 'pay_status' },
              { key: 'status' },
              { key: 'action' }
              ]
          }

          return labels.map((item, index) => {
              return Object.assign(item, keys[index])
          })
      }
  },
  async created () {
    let orgiId = 0
    if (this.$store.state.Auth.activeRoleId !== 1) {
        orgiId = this.$store.state.Auth.authUser.org_id
    }
    this.search = Object.assign({}, this.search, {
      applied_org_id: orgiId
    })
    this.loadData()
  },
  mounted () {
      core.index()
      flatpickr('#datepicker', {})
  },
  methods: {
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
        return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
        return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    },
      getOrgName (id) {
        const trainingType = this.$store.state.commonObj.organizationProfileList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
          return trainingType !== undefined ? trainingType.abbreviation_bn : ''
        } else {
          return trainingType !== undefined ? trainingType.abbreviation : ''
        }
      },
      getDesignation (id) {
        const designation = this.$store.state.commonObj.designationList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
          return designation !== undefined ? designation.text_bn : ''
        } else {
          return designation !== undefined ? designation.text_en : ''
        }
      },
      getOfficeName (id) {
        const office = this.$store.state.commonObj.officeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
          return office !== undefined ? office.text_bn : ''
        } else {
          return office !== undefined ? office.text_en : ''
        }
      },
      dataChange () {
          this.loadData()
      },
      details (item) {
          this.item = item
      },
      async searchData () {
          this.loadData()
      },
      changeStatus (baseUrl, uri, item, destination = null, dropdownName = null) {
        Store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
        RestApi.deleteData(baseUrl, `${uri}/${item.id}`).then(response => {
          if (response.success) {
            /** The following line commented as list is reloaded */
            // Store.dispatch('toggleStatus', item)
            if (destination !== null && dropdownName !== null) {
              Store.dispatch('toggleDropdownItemStatus', { itemId: item.id, destination: destination, dropdownName: dropdownName })
            }
            window.vm.$toast.success({
              title: 'Success',
              message: 'Data Updated Successfully',
              color: '#D6E09B'
            })
          } else {
            window.vm.$toast.error({
              title: 'Error',
              message: 'Operation failed! Please, try again.'
            })
          }
          Store.dispatch('mutateCommonProperties', { loading: false, listReload: true })
        })
      },
      approve (item) {
        window.vm.$swal({
          title: window.vm.$t('globalTrans.approveMsg'),
          showCancelButton: true,
          confirmButtonText: window.vm.$t('globalTrans.yes'),
          cancelButtonText: window.vm.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.changeStatus(trainingElearningServiceBaseUrl, duplicateCertificateRequestApprove, item)
          }
        })
      },
      reject (item) {
        window.vm.$swal({
          title: window.vm.$t('globalTrans.rejectMsg'),
          showCancelButton: true,
          confirmButtonText: window.vm.$t('globalTrans.yes'),
          cancelButtonText: window.vm.$t('globalTrans.no'),
          focusConfirm: false
        }).then((result) => {
          if (result.isConfirmed) {
            this.changeStatus(trainingElearningServiceBaseUrl, duplicateCertificateRequestReject, item)
          }
        })
      },
      loadData () {
          const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
          this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
          RestApi.getData(trainingElearningServiceBaseUrl, duplicateCertificateRequestList, params).then(response => {
              if (response.success) {
                  this.$store.dispatch('setList', this.getCustomDataList(response.data.data))
                  this.paginationData(response.data)
              }
              this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
          })
      },
      getCustomDataList (data) {
        const listData = data.map(item => {
          const customItem = {}
          const userTypeObj = this.userTypeList.find(userType => userType.value === item.registration_for)
          if (typeof userTypeObj !== 'undefined') {
            customItem.user_type_name = userTypeObj.text_en
            customItem.user_type_name_bn = userTypeObj.text_bn
          } else {
            customItem.user_type_name = ''
            customItem.user_type_name_bn = ''
          }
          const orgItem = {}
          const orgObj = this.$store.state.commonObj.organizationProfileList.find(org => org.value === item.applied_org_id)
          if (typeof orgObj !== 'undefined') {
            orgItem.org_name = orgObj.text_en
            orgItem.org_name_bn = orgObj.text_bn
          } else {
            orgItem.org_name = ''
            orgItem.org_name_bn = ''
          }
          const designationItem = {}
          const designationObj = this.$store.state.commonObj.designationList.find(designation => designation.value === parseInt(item.designation_id))
          if (typeof designationObj !== 'undefined') {
            designationItem.designation_name = designationObj.text_en
            designationItem.designation_name_bn = designationObj.text_bn
          } else {
            designationItem.designation_name = ''
            designationItem.designation_name_bn = ''
          }
          const jobTypeItem = {}
          const jobTypeObj = this.$store.state.TrainingElearning.commonObj.jobTypeList.find(job => parseInt(job.value) === parseInt(item.job_type_id))
          if (typeof jobTypeObj !== 'undefined') {
            jobTypeItem.job_name = jobTypeObj.text_en
            jobTypeItem.job_name_bn = jobTypeObj.text_bn
          } else {
            jobTypeItem.job_name = ''
            jobTypeItem.job_name_bn = ''
          }
          const officeTypeItem = {}
          const officeTypeObj = this.officeTypeList.find(office => office.value === parseInt(item.office_type_id))
          if (typeof officeTypeObj !== 'undefined') {
            officeTypeItem.office_type_name = officeTypeObj.text_en
            officeTypeItem.office_type_name_bn = officeTypeObj.text_bn
          } else {
            officeTypeItem.office_type_name = ''
            officeTypeItem.office_type_name_bn = ''
          }

          const officeItem = {}
          const officeObj = this.officeList.find(per => parseInt(per.value) === parseInt(item.office_id))
          if (typeof officeObj !== 'undefined') {
            officeItem.office_name = officeObj.text_en
            officeItem.office_name_bn = officeObj.text_bn
          } else {
            officeItem.office_name = ''
            officeItem.office_name_bn = ''
          }

          return Object.assign({}, item, customItem, orgItem, designationItem, officeTypeItem, officeItem)
        })
        return listData
      },
      pdfExportDetails (type) {
          this.$refs.details.pdfExport(type)
      }
  }
}
</script>
<style>
.wid span {
  display: block;
  width: 100px;
  overflow-wrap: anywhere;
}
.water-test-pdf-button2 {
    position: absolute !important;
    right: 195px !important;
    z-index: 999 !important;
    top: -39px !important;
    background: white !important;
    color: #66cc99 !important;
}
</style>
