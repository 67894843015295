<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
              <b-overlay :show="loading">
                <b-row>
                  <b-col>
                    <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail"
                      :org-id="orgiId">
                      <div class="titleDiv">
                      </div>
                    </list-report-head>
                  </b-col>
                </b-row>
                <b-col lg="12">
                  <b-row>
                    <div class="card card w-100 text-center">
                      <div class="card-inside-title font-weight-bold text-capitalize p-1 bg-light text-black">
                        <h5 class="font-weight-bold text-capitalize text-center bg-light p-1 badge-light"
                          style="color: green!important;font-size: 20px!important;"> {{ $t('elearning_config.duplicate_certificate_request') }}</h5>
                      </div>
                    </div>
                  </b-row>
                  <b-row class="ml-3">
                    <div>
                      <p>{{ $t('portal.sd_1') }}</p>
                      <p style="margin: 0px;padding: 0px;">{{ $t('portal.sd_2') }}</p>
                      <p style="margin: 0px;padding: 0px;">{{ $t('portal.sd_3') }}</p>
                      <p>{{ $t('portal.sd_4') }}</p>
                      <p v-if="$i18n.locale=='en'">{{ 'Subject' + ':' + ' Application for Duplicate Certificate of' + ' ' + getSubjectType(profile.subject_type_id) }}</p>
                      <p v-else>{{ 'বিষয়' + ':' + ' ' + getSubjectType(profile.subject_type_id) + ' -এর ডুপ্লিকেট সার্টিফিকেট -এর জন্য আবেদন' }}</p>
                    </div>
                  </b-row>
                  <b-row>
                    <div class="w-100 text-left">
                      <div class="card-inside-title p-1">
                        <h5 class="text-capitalize text-left bg-light p-2 badge-light"
                        style="color: white!important;font-size: 18px!important;;font-size: 18px!important; background-color:#02813e!important;"> {{ $t('portal.applicant_information') }}</h5>
                      </div>
                    </div>
                  </b-row>
                  <b-row>
                    <b-col id="form" lg="12" sm="12">
                        <b-row class="p-3">
                          <table border="0" class="mt-3 tabletwo" style="width:100%;">
                            <tbody>
                              <tr>
                                <td style="width: 25%"  class="text-left pl-3" >{{ $t('elearning_iabm.applicant') }} {{ $t('elearning_iabm.type') }}</td>
                                <td style="width: 2%"  class="text-left" >{{ ':' }}</td>
                                <td style="width: 30%" class="text-left">{{ getProfessionType(profile.profession_type) }}</td>
                                <td style="width: 18%"  class="text-left pl-3" ></td>
                                <td style="width: 2%"  class="text-left" ></td>
                                <td style="width: 30%" class="text-left"></td>
                              </tr>
                              <tr>
                                <td style="width: 25%"  class="text-left pl-3" >{{ $t('elearning_iabm.applicant') }} {{ $t('globalTrans.name') }}</td>
                                <td style="width: 2%"  class="text-left" >{{ ':'}}</td>
                                <td style="width: 30%" class="text-left">{{ ($i18n.locale=='bn') ? profile.name_bn : profile.name_en }}</td>
                                <td style="width: 18%"  class="text-left pl-3" ></td>
                                <td style="width: 2%"  class="text-left" ></td>
                                <td style="width: 30%" class="text-left"></td>
                              </tr>
                              <tr>
                                <td style="width: 25%"  class="text-left pl-3" >{{ $t('elearning_iabm.applicant') }} {{ $t('globalTrans.designation') }}</td>
                                <td style="width: 2%"  class="text-left" >{{ ':' }}</td>
                                <td style="width: 30%" class="text-left">
                                  <span v-if="profile.profession_type == 1 && profile.designation_id">{{ getDesignation(profile.designation_id) }}</span>
                                  <span v-if="profile.not_here_designation == 1 || profile.profession_type == 2 || profile.profession_type == 3">{{ ($i18n.locale=='bn') ? profile.designation_bn : profile.designation_en }}</span>
                                </td>
                              <td style="width: 18%"  class="text-left pl-3" ></td>
                              <td style="width: 2%"  class="text-left" ></td>
                              <td style="width: 30%" class="text-left"></td>
                              </tr>
                              <tr>
                                <td style="width: 25%"  class="text-left pl-3" >{{ $t('globalTrans.mobile') }}</td>
                                <td style="width: 2%"  class="text-left" >{{ ':' }}</td>
                                <td style="width: 30%" class="text-left">{{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(profile.mobile, { useGrouping:
                              false }) }}</td>
                                <td style="width: 25%"  class="text-left pl-3" ></td>
                                <td style="width: 2%"  class="text-left" ></td>
                                <td style="width: 30%" class="text-left"></td>
                              </tr>
                              <tr>
                                <td style="width: 25%"  class="text-left pl-3" >{{ $t('globalTrans.email') }}</td>
                                <td style="width: 2%"  class="text-left" >{{ ':'}}</td>
                                <td style="width: 30%" class="text-left">{{ profile.email }}</td>
                                <td style="width: 18%"  class="text-left pl-3" ></td>
                                <td style="width: 2%"  class="text-left" ></td>
                                <td style="width: 30%" class="text-left"></td>
                              </tr>
                            </tbody>
                          </table>
                        </b-row>
                        <b-row>
                          <div class="w-100 text-left">
                            <div class="card-inside-title p-1">
                              <h5 class="text-capitalize text-left bg-light p-2 badge-light"
                              style="color: white!important;font-size: 18px!important; background-color:#02813e!important;"> {{ $i18n.locale === 'bn' ? 'প্রশিক্ষণ/সেমিনার/কর্মশালার তথ্য' : 'Training/Seminer/Workshop Information' }}</h5>
                            </div>
                          </div>
                        </b-row>
                        <b-row class="p-3">
                          <table border="0" class="mt-3 tabletwo" style="width:100%;">
                            <tbody>
                              <tr>
                                <td style="width: 25%"  class="text-left pl-3" >{{ $t('globalTrans.title') }}</td>
                                <td style="width: 2%"  class="text-left" >{{ ':'}} </td>
                                <td style="width: 25%" class="text-left">{{ ($i18n.locale=='bn') ? profile.training_title_bn : profile.training_title_en }}</td>
                                <td style="width: 15%"  class="text-left pl-3" ></td>
                                <td style="width: 2%"  class="text-left" ></td>
                                <td style="width: 25%" class="text-left"></td>
                              </tr>
                              <tr>
                                <td style="width: 25%"  class="text-left pl-3" >{{ $t('globalTrans.start_date') }}</td>
                                <td style="width: 2%"  class="text-left" >{{ ':' }}</td>
                                <td style="width: 25%" class="text-left">{{ profile.start_date | dateFormat }}</td>
                                <td style="width: 15%"  class="text-left pl-3" ></td>
                                <td style="width: 2%"  class="text-left" ></td>
                                <td style="width: 25%" class="text-left"></td>
                              </tr>
                              <tr>
                                <td style="width: 25%"  class="text-left pl-3" >{{ $t('globalTrans.end_date') }}</td>
                                <td style="width: 2%"  class="text-left" >{{ ':'}} </td>
                                <td style="width: 25%" class="text-left">{{ profile.end_date | dateFormat }}</td>
                                <td style="width: 15%"  class="text-left pl-3" ></td>
                                <td style="width: 2%"  class="text-left" ></td>
                                <td style="width: 25%" class="text-left"></td>
                              </tr>
                            </tbody>
                          </table>
                          <table border="0" class="mt-3"  style="width:100%">
                          <tbody>
                            <tr>
                              <td style="width: 25%"  class="text-left pl-3" >{{ $t('portal.why_need_duplicate_certificate') }}</td>
                              <td style="width: 2%"  class="text-left" >{{ ':' }}</td>
                              <td style="width: 50%" class="text-left">
                                <span>{{ ($i18n.locale=='bn') ? profile.reason_bn : profile.reason_en }}</span>
                              </td>
                              <td style="width: 18%"  class="text-left pl-3" ></td>
                              <td style="width: 2%"  class="text-left" ></td>
                              <td style="width: 50%" class="text-left">
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        </b-row>
                    </b-col>
                  </b-row>
                  <b-row>
                    <!-- <b-col>
                      <b>{{ $t('globalTrans.status') }} {{ ':' }} </b>{{ getStatus(profile.status) }}
                    </b-col> -->
                    <b-col lg="12" md="12">
                      <table border="0" class="mt-3"  style="width:100%">
                        <tbody>
                          <tr>
                            <td style="width: 25%"  class="text-left pl-3" >{{ $t('elearning_iabm.total_bill') }}</td>
                            <td style="width: 2%"  class="text-left" >{{ ':'}} </td>
                            <td style="width: 30%" class="text-left">{{ $n(profile.fee_amount, { useGrouping: false })}} {{ $t('globalTrans.tk') }}</td>
                              <td style="width: 18%"  class="text-left pl-3" ></td>
                              <td style="width: 2%"  class="text-left" ></td>
                              <td style="width: 50%" class="text-left"></td>
                          </tr>
                          <tr>
                            <td style="width: 25%"  class="text-left pl-3" >{{ $t('globalTrans.date') }}</td>
                            <td style="width: 2%"  class="text-left" >{{ ':'}}</td>
                            <td style="width: 30%" class="text-left">{{ profile.created_at |dateFormat }}</td>
                              <td style="width: 18%"  class="text-left pl-3" ></td>
                              <td style="width: 2%"  class="text-left" ></td>
                              <td style="width: 50%" class="text-left"></td>
                          </tr>
                        </tbody>
                      </table>
                    </b-col>
                    <!-- <b-col>
                      <table border="0" class="mt-3"  style="width:100%">
                        <tbody>
                          <tr>
                            <th style="width: 35%" class="text-left pl-3" >{{ $t('globalTrans.date') }}</th>
                            <th style="width: 35%" class="text-right pr-3 pb-3" >{{ $t('elearning_tim.applicant') }}</th>
                          </tr>
                          <tr>
                            <td style="width: 35%" class="text-left pb-3 pl-3" >{{ profile.created_at |dateFormat }}</td>
                            <td style="width: 35%" class="text-right pb-3 pr-3" >{{ ($i18n.locale=='bn') ? profile.name_bn : profile.name_en }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </b-col> -->
                  </b-row>
                </b-col>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'
import { BRow } from 'bootstrap-vue'
import ExportPdf from './export_pdf_Preview_details'

export default {
  components: {
    ListReportHead,
    BRow
},
  name: 'FormLayout',
  props: ['item'],
  created () {
    this.profile = this.item
    this.orgiId = 1
    if (this.$store.state.Auth.activeRoleId !== 1) {
      this.orgiId = this.$store.state.Auth.authUser.org_id
    }
  },
  data () {
    return {
      profile: {},
      slOffset: 1,
      orgiId: 0,
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl
    }
  },
  computed: {
    statusList: function () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'বিচারাধীন' : 'Pending',
          text_en: 'Pending',
          text_bn: 'বিচারাধীন'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'প্রত্যাখ্যাত' : 'Rejected',
          text_en: 'Rejected',
          text_bn: 'প্রত্যাখ্যাত'
        },
        {
          value: 3,
          text: this.$i18n.locale === 'bn' ? 'অনুমোদিত' : 'Approved',
          text_en: 'Approved',
          text_bn: 'অনুমোদিত'
        }
      ]
    },
    subjectTypeList: function () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'সেমিনার' : 'Seminer',
          text_en: 'Seminer',
          text_bn: 'সেমিনার'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'প্রশিক্ষণ' : 'Training',
          text_en: 'Training',
          text_bn: 'প্রশিক্ষণ'
        },
        {
          value: 3,
          text: this.$i18n.locale === 'bn' ? 'কর্মশালা' : 'Workshop',
          text_en: 'Workshop',
          text_bn: 'কর্মশালা'
        }
      ]
    },
    professionTypeList: function () {
      return [
        {
          value: 1,
          text: this.$i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়/আওতাধীন সংস্থা' : 'MoA/Organization under MoA',
          text_en: 'MoA/Organization under MoA',
          text_bn: 'কৃষি মন্ত্রণালয়/আওতাধীন সংস্থা'
        },
        {
          value: 2,
          text: this.$i18n.locale === 'bn' ? 'অন্যান্য সরকারী সংস্থা' : 'Other Government Organization',
          text_en: 'Other Government Organization',
          text_bn: 'অন্যান্য সরকারী সংস্থা'
        },
        {
          value: 3,
          text: this.$i18n.locale === 'bn' ? 'বেসরকারি সংস্থা' : 'Non-Goverment Organization',
          text_en: 'Non-Goverment Organization',
          text_bn: 'বেসরকারি সংস্থা'
        }
      ]
    },
    loading () {
      return this.$store.state.commonObj.loading
    }
  },
  methods: {
    getSubjectType (id) {
      const trainingType = this.subjectTypeList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return trainingType !== undefined ? trainingType.text_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.text_en : ''
      }
    },
    EngBangNum (n) {
      if (this.$i18n.locale === 'bn') {
        return n.replace(/\d/g, d => '০১২৩৪৫৬৭৮৯'[d])
      } else {
        return n.replace(/[০-৯]/g, d => '০১২৩৪৫৬৭৮৯'.indexOf(d))
      }
    },
    getDesignation (id) {
      const trainingType = this.$store.state.commonObj.designationList.find(item => item.value === id)
      if (this.$i18n.locale === 'bn') {
        return trainingType !== undefined ? trainingType.abbreviation_bn : ''
      } else {
        return trainingType !== undefined ? trainingType.abbreviation : ''
      }
    },
    getStatus (id) {
      const trainingType = this.statusList.find(job => job.value === parseInt(id))
        if (this.$i18n.locale === 'bn') {
          return trainingType !== undefined ? trainingType.text_bn : ''
        } else {
          return trainingType !== undefined ? trainingType.text_en : ''
        }
    },
    getProfessionType (id) {
      const trainingType = this.professionTypeList.find(item => item.value === id)
        if (this.$i18n.locale === 'bn') {
          return trainingType !== undefined ? trainingType.text : ''
        } else {
          return trainingType !== undefined ? trainingType.text : ''
        }
    },
    pdfExport (type) {
      const reportTitle = this.$t('elearning_config.duplicate_certificate_request')
      ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.profile, this, type)
    }
  }
}
</script>

<style>.hidden_header {
  display: none
}

.card-border {
  border: 1px solid #b9bdc1;
  box-shadow: 1px 1px 6px -1px grey;
  background-color: #dee2e6;
}

[dir=ltr][mode=light] .card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 10px;
}

.reportTitle {
  font-weight: bold;
  border: 1px solid;
  padding: 8px 15px 8px 15px;
  border-radius: 11px;
}

.titleDiv {
  margin-top: 2rem;
  font-size: 18px;
  margin-bottom: 20px;
}

.report-name {
  font-weight: bold !important;
  text-transform: uppercase;
}

.my-btn {
  padding: 2px !important;
}
.data-box {
  border: 1px solid #ccc;
  padding: 20px;
  margin: 20px;
  background-color: #f0f0f0;
  margin-top: 10px;
}
#form fieldset {
  border: 1px solid #8f7c7c!important;
  float: left!important;
  margin-bottom: 25px!important;
  padding: 10px 0!important;
  width: 99.5%!important;
}
</style>
