import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-traning-external-portal'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
import { dateFormat } from '@/Utils/fliter'

const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm, type) => {
    try {
        Store.commit('mutateCommonProperties', {
            loading: true
        })
        if (i18n.locale === 'bn') {
            pdfMake.vfs = pdfFontsBn.pdfMake.vfs
        } else {
            pdfMake.vfs = pdfFontsEn.pdfMake.vfs
        }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        const pdfContent = [{
            columns: reportHeadData.reportHeadColumn
          },
          { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
          { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
          { text: reportHeadData.address, style: 'address', alignment: 'center' },
          { text: reportTitle, style: 'hh', alignment: 'center' }
        ]
        pdfContent.push({ text: '', style: 'fertilizer' })
        pdfContent.push({ text: vm.$t('portal.sd_1'), style: 'sp1' })
        pdfContent.push({ text: vm.$t('portal.sd_2'), style: 'sp2' })
        pdfContent.push({ text: vm.$t('portal.sd_3'), style: 'sp2' })
        pdfContent.push({ text: vm.$t('portal.sd_4'), style: 'sp2' })
        if (i18n.locale === 'en') {
            pdfContent.push({ text: 'Subject' + ':' + ' Application for Duplicate Certificate of' + ' ' + vm.getSubjectType(data.subject_type_id), style: 'sp3' })
        } else {
            pdfContent.push({ text: 'বিষয়' + ':' + ' ' + vm.getSubjectType(data.subject_type_id) + ' -এর ডুপ্লিকেট সার্টিফিকেট -এর জন্য আবেদন', style: 'sp3' })
        }
        pdfContent.push({
            table: {
                style: 'tableData',
                headerRows: 0,
                widths: ['23%', '2%', '75%'],
                body: [
                    [
                        { text: vm.$t('portal.applicant_information'), style: 'th1', alignment: 'left', colSpan: 3 },
                        {},
                        {}
                    ],
                    [
                        { text: vm.$t('portal.applicant_type'), style: 'ths', alignment: 'left' },
                        { text: ':', style: 'ths', alignment: 'center' },
                        { text: vm.getProfessionType(data.profession_type), style: 'tds', alignment: 'left' }
                    ],
                    [
                        { text: vm.$t('portal.applicant_name'), style: 'th', alignment: 'left' },
                        { text: ':', style: 'th', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.name_en : data.name_bn, style: 'td', alignment: 'left' }
                    ]
                ]
            },
            layout: {
                hLineWidth: function (i, node) {
                    return 0
                },
                vLineWidth: function (i, node) {
                    return 0
                }
            }
        })
        // pdfContent.push({
        //     table: {
        //         style: 'tableData',
        //         headerRows: 0,
        //         widths: ['23%', '2%', '20%', '20%', '2%', '20%'],
        //         body: [
        //             [
        //                 { text: vm.$t('portal.applicant_name'), style: 'ths', alignment: 'left' },
        //                 { text: ':', style: 'ths', alignment: 'center' },
        //                 { text: (i18n.locale === 'en') ? data.name_en : data.name_bn, style: 'tds', alignment: 'left' },
        //                 { text: vm.$t('globalTrans.mobile'), style: 'ths', alignment: 'left' },
        //                 { text: ':', style: 'ths', alignment: 'center' },
        //                 { text: vm.$n('0') + vm.$n(data.mobile, { useGrouping: false }), style: 'tds', alignment: 'left' }
        //             ],
        //             [
        //                 { text: vm.$t('globalTrans.email'), style: 'ths', alignment: 'left' },
        //                 { text: ':', style: 'ths', alignment: 'center' },
        //                 { text: data.email, style: 'tds', alignment: 'left' },
        //                 { text: '', style: 'ths', alignment: 'left' },
        //                 { text: '', style: 'ths', alignment: 'center' },
        //                 { text: '', style: 'ths', alignment: 'center' }
        //             ]
        //         ]
        //     },
        //     layout: {
        //         hLineWidth: function (i, node) {
        //             return 0
        //         },
        //         vLineWidth: function (i, node) {
        //             return 0
        //         }
        //     }
        // })
        if (parseInt(data.profession_type) === 1 && parseInt(data.designation_id)) {
        pdfContent.push({
            table: {
                style: 'tableData',
                headerRows: 0,
                widths: ['23%', '2%', '75%'],
                body: [
                    [
                        { text: vm.$t('elearning_iabm.applicant') + ' ' + vm.$t('globalTrans.designation'), style: 'ths', alignment: 'left' },
                        { text: ':', style: 'ths', alignment: 'center' },
                        { text: vm.getDesignation(data.designation_id), style: 'tds', alignment: 'left' }
                    ]
                    // [
                    //     { text: vm.$t('globalTrans.organization') + ' ' + vm.$t('globalTrans.name') + ' & ' + vm.$t('globalTrans.address'), style: 'ths', alignment: 'left' },
                    //     { text: ':', style: 'ths', alignment: 'center' },
                    //     { text: vm.getOrgName(data.org_id) + ', ' + (i18n.locale === 'en') ? data.office_mail_address : data.office_mail_address_bn, style: 'tds', alignment: 'left' }
                    // ],
                    // [
                    //     { text: vm.$t('elearning_iabm.applicant') + ' ' + vm.$t('elearning_iabm.type'), style: 'ths', alignment: 'left' },
                    //     { text: ':', style: 'ths', alignment: 'center' },
                    //     { text: vm.getProfessionType(data.profession_type), style: 'tds', alignment: 'left' }
                    // ]
                ]
            },
            layout: {
                hLineWidth: function (i, node) {
                    return 0
                },
                vLineWidth: function (i, node) {
                    return 0
                }
            }
        })
    }
        if (parseInt(data.profession_type) === 2 || parseInt(data.profession_type) === 3) {
        pdfContent.push({
            table: {
                style: 'tableData',
                headerRows: 0,
                widths: ['23%', '2%', '75%'],
                body: [
                    [
                        { text: vm.$t('elearning_iabm.applicant') + ' ' + vm.$t('globalTrans.designation'), style: 'ths', alignment: 'left' },
                        { text: ':', style: 'ths', alignment: 'center' },
                        { text: (i18n.locale === 'en') ? data.designation_en : data.designation_bn, style: 'tds', alignment: 'left' }
                    ]
                    // [
                    //     { text: vm.$t('globalTrans.organization') + ' ' + vm.$t('globalTrans.name') + ' & ' + vm.$t('globalTrans.address'), style: 'ths', alignment: 'left' },
                    //     { text: ':', style: 'ths', alignment: 'center' },
                    //     { text: (i18n.locale === 'en') ? data.other_org_name_en : data.other_org_name_bn + ', ' + (i18n.locale === 'bn') ? data.office_mail_address_bn : data.office_mail_address, style: 'tds', alignment: 'left' },
                    // ],
                    // [
                    //     { text: vm.$t('elearning_iabm.applicant') + ' ' + vm.$t('elearning_iabm.type'), style: 'ths', alignment: 'left' },
                    //     { text: ':', style: 'ths', alignment: 'center' },
                    //     { text: vm.getProfessionType(data.profession_type), style: 'tds', alignment: 'left' }
                    // ]
                ]
            },
            layout: {
                hLineWidth: function (i, node) {
                    return 0
                },
                vLineWidth: function (i, node) {
                    return 0
                }
            }
        })
    }
    pdfContent.push({
        table: {
            style: 'tableData',
            headerRows: 0,
            widths: ['23%', '2%', '75%'],
            body: [
                [
                    { text: vm.$t('globalTrans.mobile'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: vm.$n('0') + vm.$n(data.mobile, { useGrouping: false }), style: 'td', alignment: 'left' }
                ],
                [
                    { text: vm.$t('globalTrans.email'), style: 'th', alignment: 'left' },
                    { text: ':', style: 'th', alignment: 'center' },
                    { text: data.email, style: 'td', alignment: 'left' }
                ]
                // [
                //     { text: vm.$t('portal.training_information'), style: 'th1', alignment: 'left', colSpan: 3 },
                //     {},
                //     {}
                // ]
            ]
        },
        layout: {
            hLineWidth: function (i, node) {
                return 0
            },
            vLineWidth: function (i, node) {
                return 0
            }
        }
    })
    pdfContent.push({
        table: {
            style: 'tableData',
            headerRows: 0,
            widths: ['23%', '2%', '75%'],
            body: [
                [
                    { text: i18n.locale === 'bn' ? 'প্রশিক্ষণ/সেমিনার/কর্মশালার তথ্য' : 'Training/Seminer/Workshop Information', style: 'th1', alignment: 'left', colSpan: 3 },
                    {},
                    {}
                ]
                // [
                //     { text: vm.$t('portal.training_information'), style: 'th1', alignment: 'left', colSpan: 3 },
                //     {},
                //     {}
                // ]
            ]
        },
        layout: {
            hLineWidth: function (i, node) {
                return 0
            },
            vLineWidth: function (i, node) {
                return 0
            }
        }
    })
    pdfContent.push({ text: '', style: 'fertilizer' })
    pdfContent.push({
        table: {
            style: 'tableData',
            headerRows: 0,
            widths: ['23%', '2%', '75%'],
            body: [
                [
                    { text: vm.$t('globalTrans.title'), style: 'ths', alignment: 'left' },
                    { text: ':', style: 'ths', alignment: 'center' },
                    { text: (i18n.locale === 'bn') ? data.training_title_bn : data.training_title_en, style: 'tds', alignment: 'left' }
                ],
                [
                    { text: vm.$t('globalTrans.start_date'), style: 'ths', alignment: 'left' },
                    { text: ':', style: 'ths', alignment: 'center' },
                    { text: dateFormat(data.start_date), style: 'tds', alignment: 'left' }
                ],
                [
                    { text: vm.$t('globalTrans.end_date'), style: 'ths', alignment: 'left' },
                    { text: ':', style: 'ths', alignment: 'center' },
                    { text: dateFormat(data.end_date), style: 'tds', alignment: 'left' }
                ],
                [
                    { text: vm.$t('portal.why_need_duplicate_certificate'), style: 'ths', alignment: 'left' },
                    { text: ':', style: 'ths', alignment: 'center' },
                    { text: (i18n.locale === 'en') ? data.reason_en : data.reason_bn, style: 'tds', alignment: 'left' }
                ],
                [
                    { text: vm.$t('elearning_iabm.total_bill'), style: 'ths', alignment: 'left' },
                    { text: ':', style: 'ths', alignment: 'center' },
                    { text: vm.$n(data.fee_amount, { useGrouping: false }) + ' ' + vm.$t('globalTrans.tk'), style: 'tds', alignment: 'left' }
                ],
                [
                    { text: vm.$t('globalTrans.payment') + ' ' + vm.$t('globalTrans.date'), style: 'ths', alignment: 'left' },
                    { text: ':', style: 'ths', alignment: 'center' },
                    { text: dateFormat(data.created_at), style: 'tds', alignment: 'left' }
                ]
            ]
        },
        layout: {
            hLineWidth: function (i, node) {
                return 0
            },
            vLineWidth: function (i, node) {
                return 0
            }
        }
    })
        pdfContent.push({ text: '', style: 'fertilizer' })
        var docDefinition = {
            content: pdfContent,
            pageSize: 'A4',
            pageOrientation: 'Portrait',
            // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
            styles: {
                ths: {
                    fillColor: '',
                    fontSize: (i18n === 'bn') ? 12 : 10,
                    // bold: true,
                    margin: [3, 10, 3, 2]
                },
                th: {
                    fillColor: '',
                    fontSize: (i18n === 'bn') ? 12 : 10,
                    // bold: true,
                    margin: [3, 2, 3, 2]
                },
                thh: {
                    fillColor: '',
                    fontSize: (i18n === 'bn') ? 12 : 10,
                    bold: true,
                    margin: [3, 2, 3, 2]
                },
                th1: {
                    fillColor: '#02813e',
                    color: 'white',
                    fontSize: (i18n === 'bn') ? 14 : 12,
                    // bold: true,
                    margin: [3, 3, 3, 3]
                },
                tds: {
                    fontSize: (i18n === 'bn') ? 12 : 10,
                    margin: [3, 10, 3, 3]
                },
                td: {
                    fontSize: (i18n === 'bn') ? 12 : 10,
                    margin: [3, 2, 3, 2]
                },
                sp1: {
                    fontSize: (i18n === 'bn') ? 12 : 10,
                    margin: [3, 3, 3, 8]
                },
                sp2: {
                    fontSize: (i18n === 'bn') ? 12 : 10,
                    margin: [3, 2, 3, 2]
                },
                sp3: {
                    fontSize: (i18n === 'bn') ? 12 : 10,
                    margin: [3, 8, 3, 8]
                },
                header: {
                  fontSize: 16,
                  bold: true,
                  margin: [0, 0, 0, 0]
              },
              org: {
                  fontSize: 13,
                  bold: true,
                  margin: [0, -25, 10, 5]
              },
              address: {
                  fontSize: 11,
                  margin: [0, 0, 0, 5]
              },
              hh: {
                  fontSize: 14,
                  bold: true,
                  margin: [10, 10, 25, 20]
              },
              fertilizer: {
                margin: [5, 0, 0, 5]
              },
                header2: {
                    fontSize: 15,
                    bold: true,
                    margin: [15, 15, 25, 10]
                },
                header11: {
                    fontSize: 13,
                    bold: true,
                    margin: [15, 10, 10, 10]
                },
                transport: {
                    fontSize: (i18n === 'bn') ? 12 : 10,
                    margin: [10, 10, 0, 20]
                },
                headerPort1: {
                    fontSize: (i18n === 'bn') ? 12 : 10,
                    margin: [0, 20, 0, 0]
                },
                headerPort: {
                    fontSize: (i18n === 'bn') ? 12 : 10,
                    margin: [0, 4, 0, 15]
                },
                header3: {
                    fontSize: 9,
                    margin: [0, 0, 0, 4]
                },
                tableSubHead: {
                    margin: [0, 5, 0, 15]
                },
                krishi: {
                    margin: [0, -15, 0, 15],
                    alignment: 'center'
                }
            }
        }
        if (type === 'pdf') {
            pdfMake.createPdf(docDefinition, null, null, null).print()
        } else if (type === 'download') {
            pdfMake.createPdf(docDefinition, null, null, null).download('duplicate-certificate-request')
        }
    } catch (error) {
        if (error) {}
    }
    Store.commit('mutateCommonProperties', {
        loading: false
    })
}
export default {
    exportPdfDetails
}
